import React from "react";
import { SEO } from "../components/SEO";

const FAQ = (): React.ReactElement => {
  return (
    <>
      <SEO title="FAQ" metaDescription="FAQ" />
      <div className="flex flex-col lg:flex-row justify-between m-3">
        <h1 className="text-2xl">Häufig gestellte Fragen</h1>
      </div>
    </>
  );
};

export default FAQ;
